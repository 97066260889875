import { render, staticRenderFns } from "./license.vue?vue&type=template&id=1a0aeb47&scoped=true&"
import script from "./license.vue?vue&type=script&lang=js&"
export * from "./license.vue?vue&type=script&lang=js&"
import style0 from "./license.vue?vue&type=style&index=0&id=1a0aeb47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0aeb47",
  null
  
)

export default component.exports