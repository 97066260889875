<template>
  <div class="index_main_header">
    <!-- 左侧logo和乐动文化 -->
    <div class="logo" @click="toIndex">
      <div class="pic">
        <img src=".././assets/logo.png" alt="">
      </div>
      <div class="title">乐动文化</div>
    </div>
    <!-- 右侧菜单栏 -->
    <div class="menus">
      <div class="menus_item" v-for="(item,index) in menus" :class="activeItem==item.id?'activeItem':''" :key="index"
           @click="showDialog(item)">{{item.menu_title}}
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog :visible.sync="dialogTableVisible" width="1000px" modal-append-to-body append-to-body
               custom-class="dialogGetClass">
      <div class="content_box">
        <div class="title">{{title}}</div>
        <div class="getWay">
          <el-row type=" flex" align="middle" justify="center">
            <el-col :span="12" style="display:flex;flex-direction: column; align-items: center;">
              <div style="margin:15px 0">方式一</div>
              <div class="photo_box">
                <img :src="pic" alt="">
              </div>
              <div style="margin:15px 0">手机微信扫码获取入口</div>
            </el-col>
            <el-col :span="12" style="display:flex;flex-direction: column; align-items: center;">
              <div style="margin:15px 0">方式二</div>
              <div class="photo_box">
                <img :src="pic" alt="">
              </div>
              <div style="margin:15px 0">在手机微信搜索栏中搜索"{{title}}"</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'WorkspaceJsonPageheader',

    data () {
      return {
        menus: [{
          id: 0,
          menu_title: '减分识题'
        },
          {
            id: 1,
            menu_title: 'LD前台'
          },
          {
            id: 2,
            menu_title: 'LD学员'
          },
          {
            id: 3,
            menu_title: 'LD教员'
          },
          {
            id: 4,
            menu_title: 'LDBoss'
          }
        ],
        activeItem: '',
        dialogTableVisible: false,
        title: '',
        pic: '',
        ld_learner_front: require('../assets/code.png'),
        ld_learner_pic: require('../assets/code.png'),
        ld_coach_pic: require('../assets/code.png'),
        ld_boss_pic: require('../assets/code.png'),
      }
    },

    mounted () {

    },

    methods: {
      toIndex () {
        this.$router.push('/index')
      },
      showDialog (item) {
        console.log(item)
        this.activeItem = item.id
        this.title = item.menu_title
        this.dialogTableVisible = true
        this.pic = this.ld_learner_front
      }

    },
  }

</script>

<style lang="scss" scoped>
  .index_main_header {
    height: 130px;
    width: 100%;
    box-sizing: border-box;
    padding: 30px 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .logo {
      width: 350px;
      height: 100px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .pic {
        width: 100px;
        height: 100px;
      }

      .title {
        margin-left: 40px;
        font-size: 35px;
        font-weight: 600;
        color: #ffffff;
      }
    }

    .menus {
      width: 500px;
      display: flex;
      font-size: 22px;
      color: #fff;
      justify-content: flex-end;
      box-sizing: border-box;
      padding-top: 19px;

      .menus_item {
        margin-left: 20px;
        height: 23px;
        line-height: 23px;
        cursor: pointer;
      }

      .activeItem {
        color: #1a73e8
      }
    }


  }

</style>
<style lang="scss">
  .dialogGetClass {
    .el-dialog__header {
      display: none !important
    }

    .el-dialog__body {

      .title {
        width: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
      }

      .getWay {
        font-size: 18px;

        .photo_box {
          width: 200px;
          height: 200px;
        }
      }
    }
  }

</style>
