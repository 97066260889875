<template>
  <div class="license_container">
    <el-container>
      <el-main>
        <!-- 头部 -->
        <PageHeader></PageHeader>
        <!-- 中间营业执照部分 -->
        <div class="license_box">
          <el-row>
            <!-- 详细信息 -->
            <el-col :span="12">
              <div class="details_box">
                <div class="details_item">• 名称：东莞市乐动文化科技有限公司</div>
                <div class="details_item">• 类型：有限责任公司（自然人投资或控股）</div>
                <div class="details_item">• 法人代表：刘焕秀</div>
                <div class="details_item">• 统一社会信用代码：91441900MA53R38U04</div>
                <div class="details_item">• 注册资本：人民币壹拾万元</div>
                <div class="details_item">• 成立日期：2019年09月18日</div>
                <div class="details_item">• 经营期限：长期</div>
                <div class="details_item">• 住所：广东省东莞市大岭山镇大岭山元新路55号102室</div>
                <div class="details_item">• 网安备案号：34468346434365435436</div>
                <div class="details_item">• 粤ICP备：2021110472号</div>

              </div>
            </el-col>
            <!-- 执照图片 -->
            <el-col :span="12">
              <div class="photo_box">
                <img :src="license_pic" alt="">
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <!-- 底部 -->
      <el-footer>
        <BottomFooter></BottomFooter>
      </el-footer>
    </el-container>

  </div>
</template>

<script>
  import BottomFooter from '../components/BottomFooter'
  import PageHeader from '../components/PageHeader'

  export default {
    name: 'WorkspaceJsonLicense',
    components: {
      BottomFooter,
      PageHeader
    },

    data() {
      return {
        license_pic: require('../assets/license.jpg')

      };
    },

    mounted() {

    },

    methods: {

    },
  };

</script>

<style lang="scss" scoped>
  .license_container {
    background: url(".././assets/bgc.png") no-repeat center center / auto 100% rgb(245, 245, 245);
    background-size: 100% 100%;
    width: 100%;
    min-width: 960px;
    position: fixed;

    .el-container {
      height: 100%;

      .el-main {
        height: calc(100vh -100px);

        .license_box {
          width: 100%;
          height: 500px;
          //   background-color: pink;
          box-sizing: border-box;
          padding-top: 60px;

          .details_box {
            width: 560px;
            height: 380px;
            background-color: #fff;
            margin: 0 auto;
            opacity: .8;
            border-radius: 10px;
            font-size: 19px;
            font-weight: bold;
            box-sizing: border-box;
            padding: 20px;
            text-align: left;

            .details_item {
              height: 35px;
              line-height: 35px;
            }
          }

          .photo_box {
            width: 560px;
            height: 380px;
            margin: 0 auto;
          }
        }
      }

      .el-footer {
        height: 100px !important;
        background-color: #fff;

        .index_footer {
          width: 90%;
          height: 100%;
          margin: 0px auto;
          display: flex;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left_box {
            width: 350px;
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            .left_item {
              width: 90px;
              cursor: pointer;
              margin-bottom: 6px;
            }

          }
        }
      }
    }
  }

</style>
