<template>
  <div class="index_footer">
    <div class="left_box">
      <div class="left_item" @click="toLicense">营业执照</div>
      <div class="left_item" @click="toCustomerService">联系客服</div>
      <div class="left_item" @click="toAboutUS">关于我们</div>
      <div class="left_item" @click="toPolicy">隐私政策</div>
      <div class="left_item" @click="toInfringement">侵权举报</div>
    </div>
    <div class="center_box">
      <div style="width:300px;margin:0 auto; padding:20px 0;">
        <a target="_blank" href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44190002006804"
          style="display:inline-block;text-decoration:none;height:20px;line-height:20px;display:flex">
          <img :src="record_pic" style="float:left;width:20px;height:20px" />
          <p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备
            44190002006804号</p>
        </a>
      </div>
      <div style="color:#939393; text-align: center;">
        <a target="_blank" href="https://beian.miit.gov.cn/" style=" cursor: pointer;color:#939393;">
          <span>{{ICP}}</span>
        </a>
      </div>
    </div>
    <div class="right">
      <div class="right_item">违法与不良信息举报电话:10105757</div>
      <div class="right_item">xiuLedong@163.com东莞市乐动文化科技有限公司</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'WorkspaceJsonFooter',

    data() {
      return {
        recordNumber: '1010205455345757',
        ICP: '粤ICP备2021110472号-1',
        record_pic: require('../assets/record_pic.png')

      };
    },

    mounted() {

    },

    methods: {
      // 点击隐私政策
      toPolicy() {
        if (this.$route.path == '/policy') {
          this.$router.go(0)
        } else {
          this.$router.push('/policy')
        }
      },
      // 点击营业执照
      toLicense() {
        if (this.$route.path == '/license') {
          this.$router.go(0)
        } else {
          this.$router.push('/license')
        }
      },
      // 点击联系客服
      toCustomerService() {
        if (this.$route.path == '/customerService') {
          this.$router.go(0)
        } else {
          this.$router.push('/customerService')
        }
      },
      // 点击侵权举报
      toInfringement() {
        if (this.$route.path == '/infringement') {
          this.$router.go(0)
        } else {
          this.$router.push('/infringement')
        }
      },
      // 点击关于我们
      toAboutUS() {
        if (this.$route.path == '/about') {
          this.$router.go(0)
        } else {
          this.$router.push('/about')
        }
      },

    },
  };

</script>

<style lang="scss" scoped>
  .index_footer {
    width: 90%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left_box {
      width: 350px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      color: #939393;
      box-sizing: border-box;
      padding-top: 23px;

      .left_item {
        width: 90px;
        cursor: pointer;
        margin-bottom: 6px;
        height: 35px;
        line-height: 35px;

      }
    }

    .right {
      color: #939393;
      box-sizing: border-box;
      padding-top: 23px;

      .right_item {
        margin-bottom: 6px;
        height: 35px;
        line-height: 35px;
      }
    }
  }

</style>
